<template>
  <div class="tools">
    <v-tabs
      v-model="tab"
      :color="whiteLabel.colors.primary"
      icons-and-text
      show-arrows
      center-active
      fixed-tabs
      align-with-title
    >
      <v-tab key="residentsWithoutConnectionTab">
        Residentes sin inicio de sesión
        <v-icon>mdi-cellphone-off</v-icon>
      </v-tab>
      <v-tab key="unitsWithoutResidentsTab">
        Unidades sin Residentes
        <v-icon>mdi-account-off</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="residentsWithoutConnectionTab">
        <v-container>
          <v-alert elevation="0" outlined text type="info" class="mb-6"
          :color="whiteLabel.colors.primary"
            ><span
              >Los residente sin inicio de sesión son usuarios que fueron
              creados pero que aún no han vinculado su dispositivo móvil con la
              app de TappBell Mobile.</span
            >
            <br /><br />
            <span
              >Para reenviar el correo de invitación a dichos usuarios, presione
              el icono de email del Residente correspondiente, o seleccione a
              todos los que desea enviar y presione el botón Enviar a Residentes
              seleccionados.</span
            >
            <br /><br />
            <span
              >Este listado no debería mostrar ningún resultado para que todo
              este en orden.
            </span>
          </v-alert>
          <v-card class="mx-auto">
            <v-data-table
              :headers="headers_contacts"
              :items="contactsWithoutConnection"
              v-model="residentsSelected"
              :loading="loading"
              loading-text="Buscando datos ..."
              show-select
              hide-default-footer
              disable-pagination
              :no-data-text="noData"
              class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      dense
                      class="mr-2"
                      @click="
                        $router.push({
                          name: 'residents',
                          params: {
                            initial_floor: item.floor,
                            initial_unit: item.unit,
                            initial_unit_mode: item.unit_mode,
                          },
                        })
                      "
                    >
                      fa-user
                    </v-icon>
                  </template>
                  <span>Ir al Residente</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      dense
                      class="mr-2"
                      @click="sendEmail(item)"
                      v-if="item.email"
                    >
                      {{ item.email_icon }}
                    </v-icon>
                    <v-icon dense class="mr-2" v-if="!item.email">
                      mdi-email-off
                    </v-icon>
                  </template>
                  <span>Reenviar invitación de bienvenida</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-progress-linear
              v-model="progress"
              stripped
              height="3"
              color="primary"
              indeterminant
              :active="progress > 0 ? true : false"
            ></v-progress-linear>
            <v-btn
              :disabled="residentsSelected.length == 0 ? true : false"
              variant="outline"
              class="mx-4 my-4"
              :loading="progress > 0 ? true : false"
              :color="whiteLabel.colors.primary"
              dark
              @click="sendEmailSelected()"
            >
              Enviar a Residentes seleccionados
              <v-icon>mdi-email</v-icon>
            </v-btn>
          </v-card>
        </v-container>
      </v-tab-item>
      <v-tab-item key="unitsWithoutResidentsTab">
        <v-container>
          <v-alert elevation="0" outlined text type="info" class="mb-6"
          :color="whiteLabel.colors.primary"
            ><span
              >Las unidades sin residente son aquellas que fueron creadas pero
              no tienen aún ningún residente asociado.</span
            >
            <br /><br />
            <span
              >Para que el botón de timbre de una unidad sea visible por el
              visitante, debe tener creado al menos 1 Residente.</span
            >
            <br /><br />
            <span
              >Este listado no debería mostrar ningún resultado para que todo
              este en orden.
            </span>
          </v-alert>
          <v-card class="mx-auto">
            <v-data-table
              :headers="headers_units"
              :items="unitsWithoutResidents"
              :loading="loading"
              loading-text="Buscando datos ..."
              hide-default-footer
              disable-pagination
              :no-data-text="noData"
              class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      dense
                      class="mr-2"
                      @click="
                        $router.push({
                          name: 'residents',
                          params: {
                            initial_floor: item.floor,
                            initial_unit: item.id,
                            initial_unit_mode: item.mode,
                          },
                        })
                      "
                    >
                      fa-user
                    </v-icon>
                  </template>
                  <span>Ir a Residentes de la Unidad</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { db, ff } from "@/fb";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    tab: 0,
    loading: false,
    all_floors: [],
    all_units: [],
    unitsWithoutResidents: [],
    contactsWithoutConnection: [],
    residentsSelected: [],
    progress: 0,
    noData: "",
    headers_units: [
      { text: "Planta", align: "start", value: "floor_name" },
      { text: "Unidad", align: "start", value: "name" },
      { text: "Acciones", value: "actions", align: "end" },
    ],
    headers_contacts: [
      { text: "Planta", align: "start", value: "floor_name" },
      { text: "Unidad", align: "start", value: "unit_name" },
      { text: "Residente", align: "start", value: "name" },
      { text: "Email", align: "start", value: "email" },
      { text: "Acciones", value: "actions", align: "end" },
    ],
    snackbar: {
      visible: false,
      timeout: 2000,
      text: "",
      color: "",
    },
  }),

  created() {},

  mounted() {
    this.snackbar.visible = true;
    this.snackbar.text = "Cargando datos ...";
    this.snackbar.color = "success";
    this.loading = true;
    this.getAllFloors()
      .then(() => this.getAllUnits())
      .then(() => this.getUnitsWithoutResidents())
      .then(() => this.getResidentsWithoutSignIn())
      .then(() => (this.loading = false));
  },

  computed: {
    ...mapGetters({
      user: "user",
      license: "license",
      whiteLabel: "whiteLabel",
    }),
  },

  methods: {
    async getUnitsWithoutResidents() {
      this.unitsWithoutResidents = [];
      this.noData = "";
      //this.loading = true;
      ff.httpsCallable("emptyApartments")()
        .then((result) => {
          if (!result.data.error) {
            result.data.forEach((element) => {
              // Ignorar linked units ya que pueden no tener contactos y no deberian figurar en este listado.
              if (!element.linked_id) {
                let q = this.all_floors.find((f) => f.id === element.floor_id);
                this.unitsWithoutResidents.push({
                  floor: element.floor_id,
                  floor_name: q.name,
                  name: element.name,
                  mode: element.mode,
                  id: element.id,
                });
              }
            });
            if (this.unitsWithoutResidents.length == 0) {
              this.noData =
                "Todo esta correcto. No hay unidades sin residentes";
            }
            //this.loading = false;
          } else {
            this.snackbar.visible = true;
            this.snackbar.text =
              result.data.error.code + " - " + result.data.error.message;
            this.snackbar.color = "error";
            console.log(
              "error",
              result.data.error.code + " - " + result.data.error.message
            );
          }
          //this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    async getResidentsWithoutSignIn() {
      this.contactsWithoutConnection = [];
      this.noData = "";
      //this.loading = true;
      ff.httpsCallable("notLoggedInContacts")()
        .then((result) => {
          if (!result.data.error) {
            result.data.forEach((element) => {
              let q = this.all_units.find((f) => f.id === element.apartment_id);
              this.contactsWithoutConnection.push({
                unit_name: q.name,
                floor_name: q.floor_name,
                name: element.name,
                email: element.email,
                email_icon: element.email ? "mdi-email" : "mdi-email-off",
                unit: element.apartment_id,
                floor: q.floor,
                unit_mode: q.mode,
                id: element.id,
              });
            });
            if (this.contactsWithoutConnection.length == 0) {
              this.noData =
                "Todo esta correcto. No hay Residentes sin inicio de sesión";
            }
            //this.loading = false;
          } else {
            this.snackbar.visible = true;
            this.snackbar.text =
              result.data.error.code + " - " + result.data.error.message;
            this.snackbar.color = "error";
            console.log(
              "error",
              result.data.error.code + " - " + result.data.error.message
            );
          }
          //this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    async getAllFloors() {
      try {
        await db
          .collection("buildings/" + this.user.data.building + "/floors")
          .get()
          .then((floors) => {
            this.all_floors = [];
            floors.forEach((floor) => {
              this.all_floors.push({ name: floor.data().name, id: floor.id });
            });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getAllUnits() {
      try {
        await db
          .collectionGroup("apartments")
          .where("build_id", "==", this.user.data.building)
          .get()
          .then((units) => {
            units.forEach((unit) => {
              let q = this.all_floors.find(
                (f) => f.id === unit.data().floor_id
              );
              this.all_units.push({
                id: unit.id,
                name: unit.data().name,
                floor: unit.data().floor_id,
                floor_name: q.name,
                mode: unit.data().mode,
              });
            });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async sendEmail(item) {
      this.snackbar.visible = true;
      this.snackbar.text = "Reenviando e-mail de bienvenida ...";
      this.snackbar.color = "success";

      const data = {
        id: item.id,
        floor_id: item.floor,
        apartment_id: item.unit,
      };
      var contact = this.contactsWithoutConnection.findIndex(
        (x) => x.id == item.id
      );
      this.contactsWithoutConnection[contact].email_icon = "mdi-timer-sand";
      await ff
        .httpsCallable("sendEmailContact")(data)
        .then((result) => {
          if (!result.data.error) {
            this.snackbar.visible = true;
            this.snackbar.text = "E-mail enviado...";
            this.snackbar.color = "success";
            this.contactsWithoutConnection[contact].email_icon =
              "mdi-email-check";
          } else {
            this.snackbar.visible = true;
            this.snackbar.text =
              result.data.error.code + " - " + result.data.error.message;
            this.snackbar.color = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendEmailSelected() {
      let emailsToSend = this.residentsSelected.length;
      let emailsSent = 1;
      this.progress = 1;
      this.residentsSelected.forEach((resident) => {
        let item = {
          id: resident.id,
          floor: resident.floor,
          unit: resident.unit,
        };
        this.sendEmail(item).then(() => {
          this.progress = Math.floor(
            (emailsSent * 100) / this.residentsSelected.length
          );
          var contact = this.residentsSelected.findIndex(
            (x) => x.id == item.id
          );
          this.residentsSelected.splice(contact, 1);
          if (emailsToSend == emailsSent) {
            this.progress = 0;
            this.snackbar.visible = true;
            this.snackbar.text = "Todos los E-mails fueron enviados.";
            this.snackbar.color = "success";
          }
          emailsSent++;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
